<template>
  <v-dialog
    max-width="500px"
    ref="createStaffDialog"
    :value="value"
    @input="updateValue"
    @click:outside="abort"
  >
    <v-card>
      <v-card-title>
        {{ $t('createStaff') }}
      </v-card-title>
      <v-card-subtitle />
      <v-card-text>
        <staff-information
          :person="staff"
          :is-updating="processing"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="error"
          large
          text
          @click="abort"
        >
          {{ $t('abort') }}
        </v-btn>
        <v-spacer />
        <v-btn
          :loading="processing"
          :disabled="processing"
          large
          @click="createStaff"
          color="primary"
        >
          {{ $t('create') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import StaffInformation from "./person/StaffInformation"

export default {
	/**
	 * @property {Object} createStaffDialog
	 */

	name: 'CreateStaffDialog',
	props: {
		value: {
			type: Boolean
		}
	},
	components: {
		StaffInformation,
	},
	data() {
		return {
			resetStaff: {
				address: {
					name_line: null,
				},
				phone: null,
				ssn: null,
				email: null,
				pin: null,
				allowed_discounts: null,
				is_pos_admin: null,
				standardRate: null,
				eveningRate: null,
				weekendRate: null,
				publicHolidayRate: null,
				majorPublicHolidayRate: null
			},
			staff: {
				address: {
					name_line: null,
				},
				phone: null,
				ssn: null,
				email: null,
				pin: null,
				allowed_discounts: null,
				is_pos_admin: null,
				standardRate: null,
				eveningRate: null,
				weekendRate: null,
				publicHolidayRate: null,
				majorPublicHolidayRate: null
			}
		}
	},
	computed: {
		processing() {
			return this.$store.state.isUpdatingStaff
		}
	},
	methods: {
		updateValue() {
			this.$emit('input', this.$refs.createStaffDialog.value)
		},
		abort() {
			this.$emit('input', false)
		},
		createStaff() {
			this.$store.dispatch('createStaff', this.staff).then(result => {
				if(result) {
					const message = this.$t('youHaveSuccessfullyAdded') + ' ' + this.staff.address.name_line + " " + this.$t('toYourStaff')
					this.$store.commit('updateActionSuccess', {
						message: this.$t('success'),
						subMessage: message
					})
					this.staff = this.resetStaff
					this.abort()
				}
			})
		}
	}
}
</script>